.App {
  text-align: center;
}

body {
  background: #f9f9f9;
  font-size: 1.125rem;
}

.social-share-btn {
  cursor: pointer;
}

.social-share-btn div {
  margin: 0 auto;
  outline: none;
}

.modal-content {
  background: #007bff;
  color: white;
}

.fas:hover {
  color: #000;
}

.icon-display {
  font-size: 32px;
  padding: 10px;
  text-align: center;
}

.icon-name {
  font-size: 14px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  white-space: nowrap;
  opacity: .6;
}

.canvas {
  background: url(./dot.png);
  line-height: 0;
  border: 1px solid #dee2e6;
  border-radius: 0 0 3px 3px;
  padding-left: 1px;
}
.canvas canvas {
  max-width: 100%;
}

.api-link {
  padding: 20px 40px 25px 20px;
  color: #8f8f90;
  background: #333;
  border-radius: 3px;
}

.code-wrapper {
  position: relative;
  word-wrap: normal;
}

.code-wrapper:after {
  content: '';
  position:absolute;
  border-radius: 0 3px 0 0;
  top: 0;
  bottom: 18px;
  right:0;
  width: 50px;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#333333+0,333333+100&0+0,1+100 */
  background: -moz-linear-gradient(left, rgba(51,51,51,0) 0%, rgba(51,51,51,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, rgba(51,51,51,0) 0%,rgba(51,51,51,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(51,51,51,0) 0%,rgba(51,51,51,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00333333', endColorstr='#333333',GradientType=1 ); /* IE6-9 */
}

.html-key {
  color: white;
}

.html-value {
  color: #0f0;
}

@media (min-width: 576px) {
  .modal-dialog {
      max-width: 1000px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {

  .logo {
    width: 70%;
  }

  .icon-name {
    display: none;
  }

  .download-controls {
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  }

}
